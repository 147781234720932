import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import MarkdownIt from 'markdown-it';
import ReactPlayer from 'react-player';
import { isCertificatesAvailable, isOrderPossible } from '../api';

interface Block {
    header: string;
    content: string;
    video: string;
    picture: string;
}
const ICON_SIZE = 180;
const VIDEO_WIDTH = 640;
const VIDEO_HEIGHT = 360;
const PlayArrowIcon = (props: { style: React.CSSProperties }): React.JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -960 960 960"
        style={{ ...props.style, width: ICON_SIZE, height: ICON_SIZE }}
    >
        <path
            fill="rgb(38, 106, 166)"
            d="m380-300 280-180-280-180v360ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"
        />
    </svg>
);

const blocks: Block[] = [
    {
        header: 'Studie zur Datenerfassung von Smarthome-Verkehr',
        content: `
Im Projekt [KISSHOME](https://kisshome.de/) erforschen wir die Verbesserung der Sicherheit von Smarthome-Umgebungen mittels künstlicher Intelligenz. 
Insbesondere durch Malware wird Sicherheit immer wichtiger. Doch gerade für Smarthome-Geräte gibt es kaum Sicherheitslösungen. 

Das KISSHOME-Projekt setzt hier an: Unsere Idee ist, ein auf künstlicher Intelligenz basierendes Modell zu entwickeln, das Muster und Anomalien im Verhalten von Smarthome-Geräten erkennen kann. 
Im Gegensatz zu herkömmlichen Methoden können so auch bisher unbekannte Bedrohungen möglicherweise erkannt werden.

Für das Training von KI-Modellen sind große Datensätze unabdingbar und durch die Sensibilität von Netzwerkverkehr gibt es kaum öffentlich verfügbare Datensätze. 
Mit dieser Studie zielen wir daher erstmalig darauf ab, größere Mengen authentischen Netzwerkverkehrs realer Umgebungen zusammenzutragen &mdash; dafür brauchen wir Ihre Unterstützung.

Keine Sorge: Wir beschränken uns auf Metadaten und erheben keine wirklich sensiblen Daten. 
Das Zusenden Ihrer Daten an unseren Server funktioniert bequem über einen ioBroker-Adapter, welcher in Zusammenarbeit mit der ioBroker GmbH entwickelt wurde. 
Hier können Sie selbst auswählen, von welchen Geräten Daten mitgeschnitten werden. Das Mitschneiden kann jedoch zu einer Verringerung der Internetbandbreite führen.

Für das Mitmachen gibt es einen 50€-Gutschein (solange der Vorrat reicht), sowie eventuell einen brandneuen, vollausgestatteten Raspberry Pi 5. 
Lesen Sie weiter für alle wichtigen Informationen. Vielen Dank für Ihr Interesse und wir hoffen auf Ihr Mitwirken!

**<span style="color: red;">Update 13.01.2025: Es sind nur noch wenige Plätze offen.</span>** Bitte beachten Sie die [Hinweise hierzu im FAQ](/#/faq/voucher-rewards)`,
        video: 'video-1.mp4',
        picture: 'video-1.png',
    },
    {
        header: '',
        content: '',
        video: 'video-2.mp4',
        picture: 'video-2.png',
    },
    {
        header: 'Wie kann ich teilnehmen?',
        content: `
Um an der Studie teilzunehmen müssen Sie sich zunächst im Bereich ["Registrierung"](/#/register) registrieren. 
Hierbei ist wichtig, dass Sie die zur Registrierung angegebene E-Mail für den Verlauf der Studie beibehalten und stets über diese E-Mail mit uns Kontakt aufnehmen.
Sofern Sie bereits ioBroker Nutzer sind und keinen Raspberry Pi anfordern, können Sie nun direkt mit ioBroker fortfahren:

Zur Installation des Adapters öffnen Sie die Admin-Seite von ioBroker, suchen nach "KissHome Research" im Adapterbereich und installieren den Adapter.
Die Instanzeinstellungen sollten sich automatisch öffnen.
Geben Sie hier die bei der Registrierung angegebene E-Mail Adresse ein, überprüfen Sie die erkannte Fritz!Box-IP und geben Sie die Login-Daten Ihrer Fritz!Box ein.
Im Bereich "Adressen"/"Addresses" wählen Sie die Geräte aus, die Sie für die Datenerfassung freigeben möchten,
entweder aus den automatisch erkannten oder durch manuelles Hinzufügen per IP- oder MAC-Adresse.
Speichern Sie Ihre Einstellungen. Starten Sie dann das Mitschneiden in den allgemeinen Einstellungen - es kann einen Moment dauern,
bis es beginnt. Das Mitschneiden können Sie jederzeit beenden, z.B. um Geräte hinzuzufügen oder zu deaktivieren.

Sollten Sie einen Raspberry Pi angefragt haben, geben Sie uns bitte einige Tage Zeit, bis wir Ihre Anfrage bearbeiten können. 
Falls wir Ihnen einen Raspberry Pi zustellen können, informieren wir Sie sofort per E-Mail. 

Nach Erhalt muss der Raspberry Pi nur zusammengebaut und in Betrieb genommen werden. 
Dieser Vorgang besteht aus einigen einfachen Schritten, welche Sie in unserem FAQ unter ["Ich habe einen Raspberry Pi erhalten. Welche Schritte muss ich nun befolgen?"](/#/faq/getting-started) nachvollziehen können.
Die letzten Schritte sind dabei identisch zu dennen wie oben beschrieben, bis auf die Installation des Adapters, welcher bereits vorinstalliert ist.

Bei allen weiteren Fragen schauen Sie bitte in unser [FAQ](/#/faq) oder kontaktieren Sie uns unter [kisshome@internet-sicherheit.de](mailto:kisshome@internet-sicherheit.de). Wir freuen uns auf Ihre Teilnahme!
`,
        video: 'video-3.mp4',
        picture: 'video-3.png',
    },
];

function renderPart2(
    orderPossible: boolean | null,
    certificatesAvailable: boolean | null,
    opened: boolean,
    setOpened: () => void,
): React.JSX.Element {
    return (
        <div key="1">
            <h1 style={{ marginBottom: 20 }}>Was brauche ich?</h1>
            <div className="main-block reverse">
                <div style={{ flex: 1 }}>
                    <p>
                        Zur Teilnahme ist
                        <Link
                            to="/fritzbox"
                            style={{ marginLeft: 8, marginRight: 8 }}
                        >
                            eine modernere Fritz!Box
                        </Link>
                        notwendig, da der Adapter auf die Mitschneide-Funktion mit Paketfilter zurückgreift. Die Daten der ausgewählten
                        Geräte werden auf relevante Metadaten reduziert. Sämtliche Nutzdaten werden dabei vollständig
                        verworfen (technisch: Wir entfernen TCP-/UDP-Payload).
                    </p>
                    <p>
                        Geräte, die Bildmaterial streamen, wie Videokameras oder SmartTV&apos;s, schließen wir aufgrund
                        des hohen Datenvolumens aus. Außerdem sind für uns derzeit nur cloudfähige Geräte relevant, also
                        solche, die mit externen Cloud-Diensten kommunizieren. Für cloud-fähige Geräte erhoffen wir uns,
                        Abweichungen im Kommunikationsverhalten erkennen zu können.
                    </p>
                    {certificatesAvailable ? (
                        <p>
                            Wir freuen uns über jeden, der seine oder ihre Daten mit uns teilen möchte. Als Dankeschön
                            bieten wir einen 50€ Gutschein (solange der Vorrat reicht) für jeden Teilnehmer, welcher über 6 Wochen
                            hinweg, Daten von mindestens 3 cloud-fähigen Geräten liefert.
                        </p>
                    ) : null}
                    <p>
                        Falls Sie sich unsicher sind, ob Ihre Geräte mit Cloud-Diensten kommunizieren, so können Sie dies
                        <Link
                            to="/faq/cloud-enabled-devices"
                            style={{ marginLeft: 8 }}
                        >
                            über den KISSHome ioBroker-Adapter herausfinden
                        </Link>
                    : Hierzu müssen Sie den Adapter in Betrieb nehmen und nach einigen Stunden der Datenübertragung werden wir Sie dahingehend informieren.
                    </p>
                    {orderPossible ? (
                        <p>
                            Weiterhin bieten wir für diejenigen, die kein ioBroker verwenden, oder eine sehr
                            ausgeschmückte, für uns interessante Smarthome Umgebung haben, die Möglichkeit einen
                            Raspberry Pi 5 zu erhalten. Da wir nur eine begrenzte Stückzahl zur Verfügung haben, müssen
                            wir dies jedoch individuell entscheiden und eventuell mit Ihnen in Kontakt treten.
                        </p>
                    ) : null}
                </div>
                <div style={{ height: '100%' }}>
                    {!opened ? (
                        <div
                            style={{ height: VIDEO_HEIGHT, width: VIDEO_WIDTH, position: 'relative' }}
                            onClick={() => setOpened()}
                        >
                            <img
                                src="./video-2.png"
                                alt="Video"
                                style={{ width: '100%', height: '100%' }}
                            />
                            <PlayArrowIcon
                                style={{
                                    color: 'white',
                                    position: 'absolute',
                                    left: `calc(50% - ${ICON_SIZE / 2}px)`,
                                    top: `calc(50% - ${ICON_SIZE / 2}px)`,
                                    cursor: 'pointer',
                                }}
                            />
                        </div>
                    ) : (
                        <ReactPlayer
                            width={VIDEO_WIDTH}
                            url="./video-2.mp4"
                            controls
                            playing
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

const markDown: MarkdownIt = new MarkdownIt({ html: true });

const Main = (): React.JSX.Element => {
    const [orderPossible, setOrderPossible] = useState<boolean | null>(null);
    const [certificatesAvailable, setCertificatesAvailable] = useState<boolean | null>(null);
    const [videoOpened, setVideoOpened] = useState<boolean[]>([false, false, false]);

    useEffect(() => {
        isOrderPossible()
            .then(() => setOrderPossible(true))
            .catch(() => setOrderPossible(false));

        isCertificatesAvailable()
            .then(() => setCertificatesAvailable(true))
            .catch(() => setCertificatesAvailable(false));
    }, []);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 24,
                textAlign: 'justify',
            }}
        >
            {blocks.map((block, index) =>
                index === 1 ? (
                    renderPart2(orderPossible, certificatesAvailable, videoOpened[1], () => {
                        const newVideoOpened = [...videoOpened];
                        newVideoOpened[1] = true;
                        setVideoOpened(newVideoOpened);
                    })
                ) : (
                    <div key={index.toString()}>
                        <h1 style={{ marginBottom: 20 }}>{block.header}</h1>
                        <div className={`main-block ${index % 2 ? 'reverse' : ''}`}>
                            <p style={{ flex: 1 }}>
                                <span
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={{ __html: markDown.render(block.content) }}
                                />
                            </p>
                            <div style={{ height: '100%' }}>
                                {!videoOpened[index] ? (
                                    <div
                                        style={{ height: VIDEO_HEIGHT, width: VIDEO_WIDTH, position: 'relative' }}
                                        onClick={() => {
                                            const newVideoOpened = [...videoOpened];
                                            newVideoOpened[index] = true;
                                            setVideoOpened(newVideoOpened);
                                        }}
                                    >
                                        <img
                                            src={block.picture}
                                            alt="Video"
                                            style={{ width: '100%', height: '100%' }}
                                        />
                                        <PlayArrowIcon
                                            style={{
                                                position: 'absolute',
                                                left: `calc(50% - ${ICON_SIZE / 2}px)`,
                                                top: `calc(50% - ${ICON_SIZE / 2}px)`,
                                                cursor: 'pointer',
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <ReactPlayer
                                        width={VIDEO_WIDTH}
                                        url={block.video}
                                        controls
                                        playing
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                ),
            )}
        </div>
    );
};

export default Main;
